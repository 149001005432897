<template>
  <div class="no-found-page">
      <div class="no-found-box">
        <p>
          <span class="iconfont iconicon-test"></span>
        </p>
        <p class="title">页面不存在</p>
        <p class="desc">请检查页面路径是否正确</p>
        <p class="time">{{time}}秒后自动返回</p>
      </div>
  </div>
</template>
<script>
import {
  defineComponent, onMounted, onUnmounted, reactive, toRefs
} from "vue";
import {useRouter} from 'vue-router'
let stop = null
export default defineComponent({
  name: "no-found-page",
  setup(props) {
    let router = useRouter()
    let pageData = reactive({
      time:3
    })
    onMounted(()=>{
      miu()
    })

    onUnmounted(()=>{
      clearTask()
    })
    
    function miu(){
      stop = setInterval(()=>{
        if(pageData.time <= 0){
          router.back()
          clearTask()
          return;
        }
        pageData.time = pageData.time - 1
      },1000)
    }

    function clearTask(){
      clearInterval(stop)
    }

    return{
      ...toRefs(pageData)
    }

  },
});
</script>
<style lang="scss" scoped>
.no-found-page {
  .no-found-box{
    margin-top:150px;
    text-align: center;
    .iconfont{
      font-size:80px;
      color: rgb(37, 145, 238);
    }
    .title{
      margin-top:20px;
      font-size:30px;
      color: rgb(39, 117, 231);
    }
    .desc{
      margin-top:20px;
      font-size:20px;
      color: rgb(104, 104, 104);
    }
    .time{
      font-size:16px;
      margin-top:10px;
      font-weight: bold;
    }
  }
}
</style>